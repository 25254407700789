<template>
  <div id="faceCapture">
    <CSTabBar
        :tabbar="tabBarList"
        :checkedTab="tabBarType"
        @changeTabBar="changeTabBar"
    ></CSTabBar>
    <CSTabBar
        :tabbar="faceTabBarList"
        :checkedTab="faceTabBarType"
        @changeTabBar="changeFaceTabBar"
        v-show="tabBarType === 2"
    ></CSTabBar>
    <div class="filter-panel" v-if="tabBarType != 2 || faceTabBarType === 1">
      <div v-show="tabBarType === 0">
        <CSSelect style="width: 210px">
          <el-date-picker
              v-model="searchTerm.startTime"
              type="datetime"
              format="yyyy-MM-dd HH:mm"
              value-format="yyyy-MM-dd HH:mm"
              placeholder="请选择开始时间"
              :editable="false"
          ></el-date-picker>
        </CSSelect>
        <span
            style="
            display: inline-block;
            vertical-align: middle;
            margin: 0px 20px 15px 0px;
            height: 2px;
            width: 20px;
            background-color: #999;
          "
        ></span>
        <CSSelect style="width: 210px">
          <el-date-picker
              v-model="searchTerm.endTime"
              type="datetime"
              format="yyyy-MM-dd HH:mm"
              value-format="yyyy-MM-dd HH:mm"
              placeholder="请选择结束时间"
              :editable="false"
          ></el-date-picker>
        </CSSelect>
        <input
            class="cs-input"
            type="text"
            style="width: 220px"
            placeholder="搜索姓名/监控/闸机/门禁/位置"
            v-model="searchTerm.search"
            v-if="searchTerm.faceId == null"
        />
        <CSSelect v-if="searchTerm.faceId == null">
          <select style="width: 160px" v-model="searchTerm.identity">
            <option value="">身份不限</option>
            <option
                v-for="(name, identify) in identifyTypes"
                :value="identify"
                :key="identify"
            >
              {{ name }}
            </option>
          </select>
        </CSSelect>
        <CSSelect v-if="searchTerm.faceId == null">
          <select style="width: 160px" v-model="searchTerm.isAttention">
            <option value="">关注人不限</option>
            <option value="0">否</option>
            <option value="1">是</option>
          </select>
        </CSSelect>
        <CSSelect v-if="searchTerm.faceId == null">
          <select style="width: 160px" v-model="searchTerm.model">
            <option value="">识别类型不限</option>
            <option value="1">虹软</option>
            <option value="4">飞浆</option>
          </select>
        </CSSelect>
        <button
            type="button"
            @click="getFaceData()"
            class="btn btn-primary btn-sm"
        >
          查询
        </button>
      </div>
      <div v-show="tabBarType === 1">
        <CSSelect>
          <select style="width: 150px" v-model="querySubscribieParams.type">
            <option value="">关注原因不限</option>
            <option
                v-for="(item, index) in subReasonList"
                :key="item"
                :value="index"
            >
              {{ item }}
            </option>
          </select>
        </CSSelect>
        <button
            type="button"
            class="btn btn-primary btn-sm"
            @click="querySubscribe()"
        >
          查询
        </button>
      </div>
      <div v-show="tabBarType === 2 && faceTabBarType === 1">
        <CSSelect style="width: 210px">
        <el-date-picker
            v-model="searchRemoveFace.startDate"
            type="datetime"
            format="yyyy-MM-dd HH:mm"
            value-format="yyyy-MM-dd HH:mm"
            placeholder="请选择开始时间"
            :editable="false"
        ></el-date-picker>
      </CSSelect>
        <span
                style="
            display: inline-block;
            vertical-align: middle;
            margin: 0px 20px 15px 0px;
            height: 2px;
            width: 20px;
            background-color: #999;
          "
        ></span>
        <CSSelect style="width: 210px">
          <el-date-picker
              v-model="searchRemoveFace.endDate"
              type="datetime"
              format="yyyy-MM-dd HH:mm"
              value-format="yyyy-MM-dd HH:mm"
              placeholder="请选择结束时间"
              :editable="false"
          ></el-date-picker>
        </CSSelect>
        <CSSelect>
          <select style="width: 160px" v-model="searchRemoveFace.identity">
            <option value="">身份不限</option>
            <option
                v-for="(name, identity) in identityTypes"
                :value="identity"
                :key="identity"
            >
              {{ name }}
            </option>
          </select>
        </CSSelect>
        <button
            type="button"
            class="btn btn-primary btn-sm"
            @click="queryRemoveFaceData()"
        >
          查询
        </button>
      </div>
    </div>
    <div class="result-panel">
      <CSTable v-if="tabBarType === 0" :thead-top="filterHeight">
        <template v-slot:header>
          <div class="table-header-panel text-right">
            <div class="sticky-right">
              <button
                  type="btn"
                  v-if="searchTerm.faceUrl != ''"
                  class="btn btn-primary btn-sm"
                  @click="
              searchTerm.faceUrl = '';
              getFaceData();
            "
                  style="margin-right: 15px"
              >
                显示全部
              </button>
              <button
                  type="btn"
                  class="btn btn-primary btn-sm"
                  @click="searchingFace('start')"
              >
                检索人脸
              </button>
            </div>
          </div>
        </template>
        <template v-slot:thead>
          <tr>
            <th>抓拍时间</th>
            <th>姓名</th>
            <th>身份</th>
            <th>抓拍人脸图像</th>
            <th>是否关注人</th>
            <th>监控/闸机/门禁</th>
            <th>位置</th>
            <th>识别类型</th>
            <th>档案</th>
          </tr>
        </template>
        <template v-slot:tbody>
          <tr v-for="face in faceCaptureList" :key="face.id + 'a'">
            <td class="date-td">{{ face.createTime || "-" }}</td>
            <td>{{ face.name || "-" }}</td>
            <td>{{ identifyTypes[face.identity] || "否" }}</td>
            <td style="min-width:200px">
              <img
                  v-if="face.faceUrl"
                  @click="viewBigImg(face.faceUrl, '查看人脸')"
                  :src="face.faceUrl"
                  width="80px"
                  height="80px"
                  style="cursor: pointer"
              />
              <img
                  v-if="face.panorama"
                  @click="viewBigImg(face.panorama, '查看全景')"
                  :src="face.panorama"
                  width="80px"
                  height="80px"
                  style="cursor: pointer; margin-left: 10px"
              />
            </td>
            <td>{{ subReasonList[face.isAttention] || "-" }}</td>
            <td>
                <span
                    v-if="face.numbering"
                    :class="{ 'allow-click': face.type === 1 }"
                    @click="checkCameraDetail(face.cameraId)"
                >
                  {{ face.numbering }}
                </span>
              <span v-else>-</span>
            </td>
            <td>{{ face.specificLocation || "-" }}</td>
            <td>{{ face.model || "-" }}</td>
            <td>
                <span class="allow-click" @click="viewRecord(face.faceDataId)">
                  查看
                </span>
            </td>
          </tr>
        </template>
      </CSTable>
      <CSTable :thead-top="filterHeight" v-if="tabBarType === 1">
        <template v-slot:header>
          <div class="table-header-panel text-right">
            <div class="sticky-right">
              <button
                  type="button"
                  class="btn btn-primary btn-sm"
                  @click="subscribePerson()"
              >
                <svg class="icon" aria-hidden="true">
                  <use xlink:href="#icon-menujiahao"></use>
                </svg>
                关注人
              </button>
            </div>
          </div>
        </template>
        <template v-slot:thead>
          <tr>
            <th>添加时间</th>
            <th>人脸照片</th>
            <th>档案</th>
            <th>关注原因</th>
            <th>备注</th>
            <th>操作</th>
          </tr>
        </template>
        <template v-slot:tbody>
          <tr v-for="subscribe in subscribeList" :key="subscribe.id + 'b'">
            <td class="date-td">{{ subscribe.createTime }}</td>
            <td>
              <template v-if="subscribe.faceUrl">
                <img
                    @click="viewBigImg(subscribe.faceUrl)"
                    :src="subscribe.faceUrl"
                    width="80px"
                    height="80px"
                    style="cursor: pointer"
                />
              </template>
              <template v-else>-</template>
            </td>
            <td>
                <span class="allow-click" @click="viewRecord(subscribe.id)">
                  查看
                </span>
            </td>
            <td>{{ subReasonList[subscribe.type] || "-" }}</td>
            <td>
                <span
                    v-if="subscribe.comment"
                    class="allow-click"
                    @click="checkNote(subscribe)"
                >
                  查看
                </span>
              <template v-else> -</template>
            </td>
            <td>
              <button
                  type="button"
                  class="btn btn-primary dropdown-toggle"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
              >
                操作
              </button>
              <ul class="dropdown-menu">
                <li @click="changeTarget(subscribe)">
                  <a style="width: 100%">修改</a>
                </li>
                <li @click="deleteTarget(subscribe.id)">
                  <a style="width: 100%">删除</a>
                </li>
              </ul>
            </td>
          </tr>
        </template>
      </CSTable>

      <div v-if="tabBarType === 2" style="padding: 20px; font-size: 14px">

        <div v-show="faceTabBarType === 0" class="face-data">
          <span>该园区当前人脸库人脸总数: {{ faceNums.regionCodeCount }}</span>
<!--          <div v-for="regionFace in faceNums.regionCodeFace " :key="regionFace.id +'c'">-->
<!--            <img-->
<!--                :src="url+regionFace.faceUrl"-->
<!--                style="width: 200px;height:200px"-->
<!--            />-->
<!--          </div>-->
          <br>
          <span>该园区当前上传人脸总数：{{ faceNums.uploadCount }}</span>
<!--          <div v-for="regionFace in faceNums.uploadFace " :key="regionFace.id + 'd'">-->
<!--            <img-->
<!--                :src="url+regionFace.faceUrl"-->
<!--                style="width: 200px;height:200px"-->
<!--            />-->
<!--          </div>-->
          <br>
          <span>该园区当前未上传人脸总数：{{ faceNums.noUploadCount }}</span>
<!--          <div v-for="regionFace in faceNums.noUploadFace " :key="regionFace.id +'e'">-->
<!--            <img-->
<!--                :src="url+regionFace.faceUrl"-->
<!--                style="width: 200px;height:200px"-->
<!--            />-->
<!--          </div>-->
        </div>
        <div v-show="faceTabBarType === 1">
          <CSTable :thead-top="filterHeight">
            <template v-slot:thead>
              <tr>
                <th>删除时间</th>
                <th>人脸照片</th>
                <th>身份</th>
                <th>删除原因</th>
                <th>抓拍记录</th>
              </tr>
            </template>
            <template v-slot:tbody>
              <tr v-for="face in removeFaceList" :key="face.id + 'f'">
                <td class="date-td">{{ face.deleteTime }}</td>
                <td>
                  <template v-if="face.faceUrl">
                    <img
                        @click="viewBigImg(face.faceUrl)"
                        :src="face.faceUrl"
                        width="80px"
                        height="80px"
                        style="cursor: pointer"
                    />
                  </template>
                  <template v-else>-</template>
                </td>
                <td>{{ identityTypes[face.identity] }}</td>
                <td>{{ face.deleteCause }}</td>
                <td>
                                    <span class="allow-click" @click="toCaptureLog(face.id)">
                                    查看
                                    </span>
                </td>
              </tr>
            </template>
          </CSTable>
        </div>
        <div v-show="faceTabBarType === 2">
          <div class="from-item" style="margin-top:0px">
<!--            人脸删除-->
<!--            <CSRadio :items="{1: '开启', 0: '关闭'}" style="margin-left:20px" v-model="removeFaceRule.isStart"/>-->
<!--            <button class="btn btn-primary" style="margin-left:20px" @click="updateRemoveFaceRule()">保存</button>-->
            <div class="config-tip">
              <svg
                  class="icon"
                  aria-hidden="true"
              >
                <use xlink:href="#icon-menua-zu92"></use>
              </svg>
              开启后，到达指定规则后，将会删除人脸数据
            </div>
          </div>
          <div class="from-item">
            <span>该园区物业员工人脸删除规则：
              <CSRadio :items="{0:`关闭`,1:`开启`}" style="padding-left: 45px" v-model="removeFaceRule.staffRuleOpen"></CSRadio>
              <button class="btn btn-primary" style="margin-left:20px;height:30px;width:50px;padding: 0 !important;" @click="updateRemoveFaceRule()">保存</button></span>
            <span>物业员工离职后，自动删除物业员工人脸数据。</span>
          </div>
          <div class="from-item">
            <span>该园区楼内用户人脸删除规则：
              <CSRadio :items="{0:`关闭`,1:`开启`}" style="padding-left: 45px" v-model="removeFaceRule.userRuleOpen"></CSRadio>
              <button class="btn btn-primary" style="margin-left:20px;height:30px;width:50px;padding: 0 !important;" @click="updateRemoveFaceRule()">保存</button>
            </span>
            <span>1、
                            <CSSelect>
                                <select
                                    style="width: 70px; padding-left: 5px;"
                                    v-model="removeFaceRule.userRule"
                                    @change="updateRemoveFaceRule()"
                                >
                                    <option
                                        v-for="(item) in 84"
                                        :value="item+6"
                                        :key="item+6"
                                    >
                                    {{ item + 6 }}
                                    </option>
                                </select>
                            </CSSelect>
                            天未产生记录（抓拍记录/通行记录），将自动删除楼内用户人脸数据。</span>
            <span>2、被企业审核人操作“失效”后，自动删除楼内用户人脸数据。</span>
          </div>
          <div class="from-item">
            <span>该园区访客人脸删除规则：
              <CSRadio :items="{0:`关闭`,1:`开启`}" style="padding-left: 72px" v-model="removeFaceRule.visitorRuleOpen"></CSRadio>
              <button class="btn btn-primary" style="margin-left:20px;height:30px;width:50px;padding: 0 !important;" @click="updateRemoveFaceRule()">保存</button>
            </span>
            <span>1、
                            <CSSelect>
                                <select
                                    style="width: 70px; padding-left: 5px;"
                                    v-model="removeFaceRule.visitorRule"
                                    @select="updateRemoveFaceRule()"
                                >
                                    <option
                                        v-for="(item) in 84"
                                        :value="item+6"
                                        :key="item+6"
                                    >
                                    {{ item + 6 }}
                                    </option>
                                </select>
                            </CSSelect>
                            天未产生记录（抓拍记录/通行记录），将自动删除访客人脸数据。</span>
          </div>
          <div class="from-item">
            <span>该园区陌生人人脸删除规则：
              <CSRadio :items="{0:`关闭`,1:`开启`}" style="padding-left: 58px" v-model="removeFaceRule.strangerRuleOpen"></CSRadio>
              <button class="btn btn-primary" style="margin-left:20px;height:30px;width:50px;padding: 0 !important;" @click="updateRemoveFaceRule()">保存</button>
            </span>
            <span>1、
                            <CSSelect>
                                <select
                                    style="width: 70px; padding-left: 5px;"
                                    v-model="removeFaceRule.strangerRule"
                                    @select="updateRemoveFaceRule()"
                                >
                                    <option
                                        v-for="item in 84"
                                        :value="item+6"
                                        :key="item+6"
                                    >
                                    {{ item + 6 }}
                                    </option>
                                </select>
                            </CSSelect>
                            天自动删除陌生人人脸数据。</span>
          </div>
        </div>
      </div>
      <Pagination
          v-if="tabBarType === 0 || tabBarType === 1 || faceTabBarType === 1"
          name="Pagination"
          componentName="Pagination"
      ></Pagination>
    </div>
    <CSDialog
        dialog-width="540px"
        dialog-title="搜索人脸"
        :dialog-visible="visibleList.searchFaceVisible"
        @onClose="visibleList.searchFaceVisible = false"
        @onConfirm="getFaceData()"
        dialog-confirm-btn-text="确定检索"
    >
      <template v-slot:dialog-content>
        <div class="preview-tenant" style="font-size: 24px; padding: 30px">
          <div class="field" style="margin-bottom: 30px">
            <span class="field-label">人脸照片</span>
            <div
                v-if="!searchTerm.faceUrl"
                @click="uploadSearchFace()"
                class="upload-photo"
            >
              <img src="@/assets/upload.png" alt="" style="margin: 10px auto"/>
              <p style="color: #999">上传图片</p>
            </div>
            <div v-else class="photo-view">
              <img :src="snapImgHost + searchTerm.faceUrl" alt=""/>
              <img
                  @click="deleteFacePhoto('searchTerm')"
                  src="../../assets/icon4.png"
                  style="
                  width: 15px;
                  height: 15px;
                  position: absolute;
                  top: -5px;
                  right: -5px;
                "
              />
            </div>
            <input
                type="file"
                accept="image/*"
                @change="showImage($event)"
                id="searchFaceBtn"
                hidden
            />
          </div>
          <span style="font-size: 20px; color: #999999; line-height: 28px">
            <svg aria-hidden="true" class="icon">
              <use xlink:href="#icon-menua-zu92"></use>
            </svg>
            通过检索人脸照片，快速查找此人所有抓拍图像。
          </span>
        </div>
      </template>
    </CSDialog>
    <!-- 添加关注人 -->
    <AddSubscribe></AddSubscribe>
    <CameraControls>
      <template v-slot:video>
        <video ref="video" src=""></video>
      </template>
    </CameraControls>
    <TargetPersonDetail></TargetPersonDetail>
    <ViewImageModal></ViewImageModal>
  </div>
</template>

<script>
import CSTabBar from "@/components/common/CSTabBar";
import CSSelect from "@/components/common/CSSelect";
import CSDialog from "@/components/common/CSDialog";
import CSRadio from "@/components/common/CSRadio";
import Pagination from "@/components/Pagination";
import TargetPersonDetail from "@/components/SecurityCamera/targetPersonDetail";
import AddSubscribe from "@/components/SecurityCamera/addSubscribe";
import ViewImageModal from "@/components/ViewImageModal";
import CameraControls from "@/components/SecurityCamera/cameraControls";
import {
  closeRealTimeStreamUrl,
  deleteAttentionUserUrl,
  openRealTimeStreamUrl,
  queryCapturePhotoLogUrl,
  queryFaceDataUrl,
  uploadSnapshotImageUrl,
  snapImgHost,
  getFaceDbNumUrl,
  queryRemoveFaceDataUrl,
  queryRemoveFaceRuleUrl,
  updateRemoveFaceRuleUrl,
} from "@/requestUrl";
import {initStreamVideoPlayer} from "@/utils";
import CSTable from "@/components/common/CSTable";
// const url = "https://cosmos-snapshot.oss-cn-shanghai.aliyuncs.com/";
export default {
  components: {
    CSTable,
    CSTabBar,
    CSSelect,
    CSDialog,
    Pagination,
    TargetPersonDetail,
    AddSubscribe,
    CameraControls,
    ViewImageModal,
    CSRadio,
  },
  name: "faceCapture",
  data() {
    return {
      url: "https://cosmos-snapshot.oss-cn-shanghai.aliyuncs.com/",
      snapImgHost,
      videoPlayers: {},
      filterHeight: 0,
      isFullscreen: false,
      deviceTypes: {
        1: "监控",
        2: "闸机",
        3: "门禁",
      },
      subReasonList: {
        1: "vip",
        2: "可疑人",
      },
      tabBarType: 0,
      tabBarList: {
        0: "抓拍记录",
        1: "关注人列表",
        2: "人脸库数据",
      },
      faceTabBarType: 0,
      faceTabBarList: {
        0: "当前人脸数据",
        1: "删除人脸数据记录",
        2: "设置删除人脸数据规则",
      },
      querySubscribieParams: {
        type: "",
      },
      faceNums: {
        count: 0,//人脸库人脸总数
        regionCodeCount: 0,//园区人脸总数
        regionCodeFace: [],
        uploadCount: 0,//该园区当前上传人脸总数
        uploadFace: [],
        noUploadCount: 0,//该园区当前未上传人脸总数
        noUploadFace: [],
      },
      searchTerm: {
        regionCode: this.$vc.getCurrentRegion().code, //区域编码
        search: "", //搜索框内容
        isAttention: "", //是否为关注人
        identity: "", //身份
        faceUrl: "",
        model: "",
        startTime: dayjs().subtract(7, "days").format("YYYY-MM-DD 00:00"), //开始时间
        endTime: dayjs().format("YYYY-MM-DD 23:59"), //结束时间
      },
      identifyTypes: {
        4: "访客",
        3: "楼内用户",
        2: "物业员工",
        1: "陌生人",
      },
      visibleList: {
        searchFaceVisible: false,
        subscribePersonVisible: false,
        changeSubscribePersonVisible: false,
        noteVisible: false,
      },
      faceCaptureList: [],
      subscribeList: [],
      identityTypes: {
        1: "陌生人",
        2: "物业员工",
        3: "楼内用户",
        4: "访客",
      },
      searchRemoveFace: {
        identity: "",
        startDate: dayjs().subtract(7, "days").format("YYYY-MM-DD 00:00"), //开始时间
        endDate: dayjs().format("YYYY-MM-DD 23:59"), //结束时间
      },
      removeFaceList: [],
      removeFaceRule: {},
    };
  },
  created() {
    window.addEventListener("keydown", this.CameraSelect);

    this.changeTabBar(0);
    this.changeFaceTabBar(0);
  },
  mounted() {
    this.$vc.on(this.$route.path, "pagination_page", "event", (page) => {
      if (this.tabBarType === 0) this.getFaceData(page);
      else if (this.tabBarType === 2) this.queryRemoveFaceData(page);
      else this.querySubscribe(page);
    });

    this.$vc.on(this.$route.path, "faceCapture", "refresh", (page) => {
      if (this.tabBarType === 0) this.getFaceData(page);
      else this.querySubscribe(page);
    });
    this.filterHeight = document.querySelector('.filter-panel')?.offsetHeight;
    window.addEventListener('resize', () => {
      this.filterHeight = document.querySelector('.filter-panel')?.offsetHeight;
    })
  },
  updated() {
    this.filterHeight = document.querySelector('.filter-panel')?.offsetHeight;
  },
  destroyed() {
    window.removeEventListener("keydown", this.CameraSelect);
  },
  deactivated() {
    window.removeEventListener("keydown", this.CameraSelect);
  },
  methods: {
    CameraSelect(e) {
      if (e.keyCode == 13) {
        if (this.tabBarType === 0) {
          this.getFaceData();
        } else if (this.tabBarType === 1) {
          this.querySubscribe();
        }
      }
    },
    // 获取人脸库总数
    getFaceNum() {
      this.$fly.get(getFaceDbNumUrl, {
        regionCode: this.$vc.getCurrentRegion().code,
      }).then((res) => {
        this.faceNums = res.data || 0;
      });
    },
    fullScreen(target, act = "requestFullscreen", el) {
      const cameraWindow =
          el || document.querySelector(".cameraWindow" + target?.id);
      if (act === "requestFullscreen") {
        document.body.requestFullscreen();
        this.$vc.emit(this.$route.path, "cameraManage", "cameraControls", {
          target,
          visible: true,
        });
      }
      if (!cameraWindow) {
        return;
      }
      document.body.addEventListener("fullscreenchange", (e) => {
        if (document.fullscreenElement) {
          cameraWindow.style.position = "fixed";
          cameraWindow.style.top = "0";
          cameraWindow.style.left = "0";
          cameraWindow.style.right = "0";
          cameraWindow.style.bottom = "0";
          cameraWindow.style.width = "100vw";
          cameraWindow.style.height = "100vh";
          cameraWindow.style.background = "rgba(0, 0, 0, 1)";
          cameraWindow.style.zIndex = "2004";
          this.isFullscreen = true;
        } else {
          this.isFullscreen = false;
          cameraWindow.style.position = "relative";
          cameraWindow.style.width = "540px";
          cameraWindow.style.height = "340px";
          cameraWindow.style.zIndex = "1";
          cameraWindow.style.background = "rgba(0, 0, 0, .2)";
          this.$vc.emit(this.$route.path, "cameraManage", "cameraControls", {
            target: null,
            visible: false,
          });
        }
      });
    },
    /**
     * 列表查看全屏监控
     * @param {Number} camearId 监控id
     * */
    checkCameraDetail(cameraId) {
      console.log(this.$refs.video, "监控摄像头");
      this.queryCameraStreamUrl(cameraId, this.$refs.video);
      this.fullScreen({id: cameraId}, "requestFullscreen", this.$refs.video);
    },
    /**
     * 关闭视频流
     * @param {Number} id 监控id
     * */
    closeCameraStream(id) {
      try {
        this.videoPlayers[id].pause();
      } catch (err) {
        console.log(err, "暂停视频错误");
      }
      return this.$fly
          .get(closeRealTimeStreamUrl, {id})
          .then((res) => res.data || "");
    },
    /**
     * 获取视频流地址
     * @param {Number} id 监控id
     * @param {String} videoEl 显示监控的标签id或者元素
     * */
    async queryCameraStreamUrl(id, videoEl) {
      const data = await this.$fly.get(openRealTimeStreamUrl, {id});
      const cameraStreamUrl = data.data;
      try {
        console.log("开始调用初始化监控函数", id);
        const player = await initStreamVideoPlayer(
            (err) => {
              console.log(err, "监控的错误信息");
            },
            videoEl ? videoEl : `camera_${id}`,
            {
              url: cameraStreamUrl,
            }
        );
        this.videoPlayers[id] = player;
      } catch (err) {
        console.log(err, "初始化监控错误信息");
      }
    },
    /**
     * 查询关注人列表
     * @param {Number} pageNo
     * @param {Number} pageSize
     * */
    querySubscribe(pageNo = 1, pageSize = 10) {
      this.$fly
          .post(queryFaceDataUrl, {
            regionCode: this.$vc.getCurrentRegion().code,
            pageNo,
            pageSize,
            ...this.querySubscribieParams,
          })
          .then((res) => {
            if (res.code !== 0) {
              return;
            }
            this.$vc.emit(this.$route.path, "pagination", "init", {
              total: res.data.total,
              currentPage: pageNo,
              pageSize,
            });
            this.subscribeList = res.data.datas;
          });
    },
    /**
     * 查询删除人脸数据
     */
    queryRemoveFaceData(pageNo = 1, pageSize = 10) {
      this.$fly.post(queryRemoveFaceDataUrl, {
        regionCode: this.$vc.getCurrentRegion().code,
        pageNo,
        pageSize,
        ...this.searchRemoveFace
      })
          .then((res) => {
            if (res.code !== 0) {
              return;
            }
            this.$vc.emit(this.$route.path, "pagination", "init", {
              total: res.data.total,
              currentPage: pageNo,
              pageSize,
            });
            this.removeFaceList = res.data.datas;
          })
    },
    /**
     * 查询删除人脸规则
     */
    queryRemoveFaceRule() {
      this.$fly.get(queryRemoveFaceRuleUrl, {
        regionCode: this.$vc.getCurrentRegion().code
      })
          .then((res) => {
            if (res.code != 0) {
              return;
            }
            this.removeFaceRule = res.data;
          })
    },
    /**
     * 更新删除人脸规则
     */
    updateRemoveFaceRule() {
      this.$fly.post(updateRemoveFaceRuleUrl, {
        regionCode: this.$vc.getCurrentRegion().code,
        ...this.removeFaceRule,
      })
          .then((res) => {
            if (res.code != 0) {
              return;
            }
          })
    },
    toCaptureLog(faceId) {
      this.$router.push({
        name: 'capturePhotoLog',
        params: {faceId}
      })
    },
    changeTabBar(index) {
      this.tabBarType = index;
      const hanlder = {
        0: this.getFaceData,
        1: this.querySubscribe,
        2: this.getFaceNum,
      };
      hanlder[index]();
    },
    changeFaceTabBar(index) {
      this.faceTabBarType = index;
      const hanlder = {
        0: this.getFaceNum,
        1: this.queryRemoveFaceData,
        2: this.queryRemoveFaceRule,
      };
      hanlder[index]();
    },
    getFaceData(pageNo = 1, pageSize = 10) {
      this.$fly
          .post(queryCapturePhotoLogUrl, {
            pageNo,
            pageSize,
            ...this.searchTerm,
          })
          .then((res) => {
            this.$vc.emit(this.$route.path, "pagination", "init", {
              total: res.data.total,
              currentPage: pageNo,
              pageSize,
            });
            this.faceCaptureList = res.data.datas;
            console.log(this.faceCaptureList);
            setTimeout(() => {
              this.$vc.emit('loading', {});
            }, 3000)

            this.visibleList.searchFaceVisible = false;
          });
    },
    searchingFace(act) {
      if (act === "start") {
        this.searchTerm.faceUrl = "";
        this.visibleList.searchFaceVisible = true;
      } else if (act === "confirm") {
        if (this.searchTerm.facePhoto === "") {
          this.$vc.toast("请选择图片");
        } else {
          this.visibleList.searchFaceVisible = false;
          console.log(this.searchTerm.facePhoto);
          this.searchTerm.facePhoto = "";
        }
      }
    },
    // 添加关注人对话框
    subscribePerson() {
      var data = {
        boolean: true,
      };
      this.$vc.emit(this.$route.path, "faceCapture", "addSubscribe", data);
    },
    viewRecord(recordId) {
      this.$vc.emit(this.$route.path, "faceCapture", "checkFile", recordId);
    },
    uploadSearchFace() {
      $("#searchFaceBtn").trigger("click");
    },
    /**
     * 查看大图
     * @param {String} imgSrc 图片地址
     * */
    viewBigImg(imgSrc, title = "查看图片") {
      this.$vc.emit(this.$route.path, "viewImageModal", "view", {
        photos: [imgSrc],
        title,
      });
    },
    viewImg() {
      this.showImg = false;
    },
    checkVideo(target) {
      console.log("checkVideo", target);
      var data = {
        boolean: true,
        target,
      };
      this.$vc.emit(this.$route.path, "cameraVideo", "checkVideo", data);
    },
    // 修改
    changeTarget(target) {
      this.$vc.emit(this.$route.path, "faceCapture", "changeSubscribe", target);
    },
    /**
     * 删除关注人
     * @param {Number} id 删除人id
     * */
    deleteTarget(id) {
      this.$CSDialog.warning({
        title: "提示",
        messageHtml: "<span>确认删除吗?</span>",
        onConfirm: () => {
          this.$fly.get(deleteAttentionUserUrl, {id}).then((res) => {
            if (res.code !== 0) {
              return;
            }
            this.$CSDialog.instance.closeDialog();
            this.querySubscribe();
            this.$vc.toast("删除成功");
          });
        },
      });
    },
    async showImage(event) {
      const photoFile = event.target.files;
      if (photoFile && photoFile.length > 0) {
        const photo = photoFile[0];
        if (photo.size > 1024 * 1024 * 10) {
          this.$vc.toast("图片大小不能超过 10MB!");
          return false;
        }
        this.searchTerm.faceUrl = await this.uploadSnapImg(photo);
      }
    },
    uploadSnapImg(file) {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("userId", this.$vc.getCurrentStaffInfo().id);
      return this.$fly
          .post(uploadSnapshotImageUrl, formData)
          .then((res) => res.data || "");
    },
    deleteFacePhoto() {
      this.searchTerm.faceUrl = "";
    },
    checkNote(target) {
      this.$CSDialog.alert({
        title: "备注",
        messageHtml: `<span style="display: inline-block;margin: 0 auto;text-align: left;">${target.comment}</span>`,
        width: "450px",
      });
    },
  },
  watch: {
    "visibleList.subscribePersonVisible": function (val, newVal) {
      console.log(val, newVal);
      this.addChangeSubPerson.isVIP = 0;
    },
  },
};
</script>

<style lang="stylus" scoped>
.preview-tenant {
  color: #000;

  .field {
    &-label {
      width: 100px;
      display: inline-block;
      margin-right: 30px;
      text-align: right;
      vertical-align: top;
      margin-bottom: 30px;
    }

    &-content {
      display: inline-block;
      max-width: calc(100% - 240px);
    }
  }
}

.el-divider__text.is-left {
  font-size: 24px;
}

.class-a {
  position: relative;
  margin-right: 5px;
  top: 2px;
  left: 0;
  display: inline-block;
  width: 20px;
  height: 20px;
  background: url('../../assets/icon5.png');
}

.class-b {
  position: relative;
  margin-right: 5px;
  top: 2px;
  left: 0;
  display: inline-block;
  width: 20px;
  height: 20px;
  background: url('../../assets/icon3.png');
}

.face-data span {
  margin-top: 20px;
  display: block;
}

.config-tip
  color #999
  margin-bottom 20px
  margin-top 10px

.from-item
  margin-top 30px

  span
    display block
    margin-top 5px
</style>
